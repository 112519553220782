import React, { useContext } from 'react';

import { 
  Box, Typography
} from '@mui/material';
import { UserContext } from '@context/user';

import PublicIcon from '@mui/icons-material/Public';
import GroupsIcon from '@mui/icons-material/Groups';
import LockIcon from '@mui/icons-material/Lock';
import { LoadableText } from '@components/atoms';

export default function PostPrivacy(props) {
  const { post, isLoading } = props;
  const { activeOrganisation } = useContext(UserContext);

  var iconColor = 'disabled';

  var text = '';
  var icon = null;

  if(post?.private){
    text = 'Only You';
    icon = <LockIcon color={iconColor} sx={{ fontSize: '0.875rem' }}/>;
  }
  else if(post?.organisationId && post?.organisationId == activeOrganisation?.id ){
    text = `${activeOrganisation.name} members`;
    icon = <GroupsIcon color={iconColor} sx={{ fontSize: '0.875rem' }}/>;
  }
  else if(post?.teamIds.length > 0 ){
    var teamNames = [];

    post?.teamIds.forEach(teamId => {
      var team = activeOrganisation.myTeams.filter(t => t.id == teamId)[0];

      if(team.name){
        teamNames.push(team.name);
      }
    });

    text = `${teamNames.join(',')} members`;
    icon = <GroupsIcon color={iconColor} sx={{ fontSize: '0.875rem' }}/>;
  }else{
    text = 'Public';
    icon = <PublicIcon color={iconColor} sx={{ fontSize: '0.875rem' }}/>;
  }

  return (
    <Box sx={{ display : 'flex', alignItems: 'center' }}>
      { text != '' &&
      <>
        <Box sx={{ marginRight: 0.25 }}>
          {icon}
        </Box>
        
        <Typography component='div' variant='body2' color='text.disabled'>
          <LoadableText minWidth={100} isLoading={isLoading} text={text}/>
        </Typography>
      </>
      }
    </Box>
  );
}