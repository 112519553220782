import React, { useContext, useEffect, useState } from 'react';

import { 
  Box, 
  ThemeProvider,
  Button,
  Typography
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { Icon } from '../atoms';
import { forceLightTheme } from '@helpers/theme';
import { UserContext } from '@context';

function Page(props) {
  const { handleLogout } = useContext(UserContext);
  const { onClick } = props;
  const [shouldShowLogoutButton, setShouldShowLogoutButton] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldShowLogoutButton(true);
    }, 10000);
    
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Box 
      onClick={onClick}
      sx={{ 
        background: '#fff', 
        height: '100vh', 
        width: '100vw', 
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center' }}>
      <Box>
        <Icon type='treadmill'/>
        {shouldShowLogoutButton && 
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: 2 }}>
          <Typography sx={{ p: 2 }}>
          Having issues logging in?
          </Typography>
          <Button onClick={handleLogout} variant='contained' size='small'>
            Logout 
          </Button>
          
        </Box>
        }
      </Box>
    </Box>
  );
}

export default function LoadingPage(props){
  const theme = useTheme();
  return (
    <ThemeProvider theme={forceLightTheme(theme)}>
      <Page {...props}/>
    </ThemeProvider>
  );
}