import React, { useContext, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { 
  Box,
  CircularProgress,
  Stack,
  Button,
  Divider
} from '@mui/material';

import CreateIcon from '@mui/icons-material/Create';

import { EmptyState, ActionsButton } from '@components/molecules';

import Post from './post';
import { PostFeedContext, PostFeedProvider } from './post-feed-context';
import { NewPostContext } from '@components/organisms';
import ViewPost from './view/view-post';
import { EventsContext } from '@context/events';
import { CommunityFilters } from '@components/v2/organisms';

const EmptyLoadingState = () => {
  var rows = [];
  for (var i = 0; i < 10; i++) {
    rows.push(<Post key={i} isLoading={true}/>);
  }
  return rows;
};

function PostFeed(props) {
  const { showCreateCheckInFab, emptyStateTitle, emptyStateMessage, areQuickFilersEnabled } = props;
  const { 
    posts, 
    isLoading, 
    savePost, 
    moreResultsLeft, 
    refresh, 
    isRefreshing, 
    loadNextPage, 
    isViewingPost,
    postBeingViewed,
    isJustMe, 
    setIsJustMe,
    values
  } = useContext(PostFeedContext);
  const { openNewPostDialog } = useContext(NewPostContext);
  const { trackEvent } = useContext(EventsContext);

  useEffect(() => {
    if(!isViewingPost){
      trackEvent('Community:Posts:Open');
    }
  }, []);

  const openCreatePost = () => {
    trackEvent('Community:Posts:Create:Open');
    openNewPostDialog({ 
      title: 'New Check-In', 
      placeholder: 'Your Check-In..', 
      buttonAction: savePost, 
      successToastMessage : 'Check-in Created!', 
      isAccessControlEnabled: true ,
      isPrivateEnabled: true
    });
  };

  return (
    <Stack 
      direction="column" 
      sx={{ width: '100%' }}>
      {areQuickFilersEnabled &&
        <>
          <Divider />
          <Box sx={{ p: 0.5, display: 'flex', justifyContent: 'center' }}>
            <Button disabled={isLoading}
              sx={{ marginRight: 1, p: 1 }}
              onClick={() => {if(isJustMe)setIsJustMe(false);}}
              variant={isJustMe ? 'text' : 'contained'}>All</Button>
            <Button disabled={isLoading}
              onClick={() => {if(!isJustMe)setIsJustMe(true);}}
              sx={{ marginLeft: 1, p: 1 }}
              variant={isJustMe ? 'contained' : 'text'}>Just Me</Button>
          </Box>
          <Divider />
        </>
      }
      <CommunityFilters />
      <Box>
        {(isLoading && posts.length === 0)  &&
          <EmptyLoadingState />
        }
        {(!isLoading && !isRefreshing && posts.length === 0 ) &&
      (
        <Box p={2}>
          <EmptyState iconType={isJustMe ?  'chat' : 'search' }
            title={isJustMe ?  'You Have No Check-Ins Yet!' : emptyStateTitle} 
            message={isJustMe ?  'To post your first Check-In select the + icon in the bottom right corner.' : emptyStateMessage} />
        </Box> 
      )}
        {isRefreshing && 
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
          <CircularProgress size={'1.5rem'}/>
        </Box>
        }
        {!isLoading && posts.length > 0 &&
        <InfiniteScroll
          dataLength={posts.length}
          next={loadNextPage}
          hasMore={moreResultsLeft}
          loader={
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
              <CircularProgress size={'1.5rem'}/>
            </Box>
          }
          refreshFunction={refresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
        >
          {posts.map((post) =>
            <Post 
              key={post.id}
              post={post}
              isLoading={false}
              refresh={refresh}/>     
          )}
        </InfiniteScroll>
        }

      </Box>
      {(showCreateCheckInFab && !isLoading) &&
        <ActionsButton dataTestId='community-check-ins-fab'
          actions={[
            { dataTestId: 'community-check-ins-fab-new-check-in', icon: <CreateIcon />, name: 'Post New Check-In', onClick: () => openCreatePost() } 
          ]}/>
      }
      {postBeingViewed &&
        <ViewPost backLocation={values?.checkInId ? '/#/community' : null}/>
      }
    </Stack>
  );
}

export default function PostFeedComponent(props){
  var { showCreateCheckInFab, emptyStateTitle='No Matches Found', emptyStateMessage='Please try another search', areQuickFilersEnabled=false } = props;
  return (
    <PostFeedProvider>
      <PostFeed showCreateCheckInFab={showCreateCheckInFab}
        emptyStateTitle={emptyStateTitle}
        emptyStateMessage={emptyStateMessage}
        areQuickFilersEnabled={areQuickFilersEnabled}/>
    </PostFeedProvider>
  );
}