import React from 'react';
import { useHistory } from 'react-router';

import { 
  Box,
  Avatar as MuiAvatar,
  Skeleton,
  Typography
} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { OptionalTooltip } from  './';
import { useTheme } from '@emotion/react';

function stringToHslColor(str, s, l) {
  if(!str){
    return 'hsl(0, 0%, 50%)';
  }

  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return 'hsl('+h+', '+s+'%, '+l+'%)';
}

export default function Avatar(props) {
  const { size, isLoading, src, name, iconSize, linkTo, iconType='person', clickable=false, showTooltip=false, text, dataTestId, imgProps={}, onClick, children } = props;
  const history = useHistory();
  const theme = useTheme();

  const handleClick = () => {
    if(onClick){
      onClick();
    }
    if(linkTo){
      history.push(linkTo);
    }
  };

  if(isLoading){
    return(
      <Skeleton variant="rectangular" sx={{ borderRadius: 2 }}>
        <Avatar data-testid={dataTestId} sx={{ width: props.size, height: props.size, borderRadius: 1 }} onClick={onClick}/>
      </Skeleton>
    );
  }

  var getIcon = () => {
    if(iconType == 'organisation'){
      return <GroupsIcon sx={{ fontSize: iconSize ?? 35 }}/>;
    }
    if(iconType == 'camera'){
      return <CameraAltIcon sx={{ fontSize: iconSize ?? 35 }}/>;
    }
    if(iconType == 'imageUpload'){
      return <CloudUploadIcon sx={{ fontSize: iconSize ?? 35 }}/>;
    }

    return <PersonIcon sx={{ fontSize: iconSize ?? 35 }}/>;
  };

  return (
    <Box onClick={handleClick}>
      <OptionalTooltip title={name} isEnabled={showTooltip}>
        <MuiAvatar src={src} 
          imgProps={imgProps}
          data-testid={dataTestId} 
          sx={{ width: size, 
            height: size, 
            borderRadius: 1, 
            background: children ? theme.palette.background.default : stringToHslColor(name, 60, 60), 
            cursor: clickable ? 'pointer' : 'auto'
          }}>
          {children && children}
          {!children &&
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyItems: 'center', textAlign: 'center' }}>
              <Box>
                {getIcon()}
              </Box>
              {text &&
                <Typography variant='caption'>
                  {text}
                </Typography>
              }
            
            </Box>
          }
        </MuiAvatar>
      </OptionalTooltip>
    </Box>
  );
}