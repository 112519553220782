import React, { useState, useContext, useEffect } from 'react';
import { 
  Box,
  ButtonBase,
  Card,
  Stack,
  Typography
} from '@mui/material';

import { useTheme } from '@emotion/react';
import { Icon } from '@components/atoms';
import Image from 'mui-image';
import { COOKIE_NAMES, getCookie } from '@helpers/cookies';

import Practitioner from './practitioner';
import { UserContext } from '@context';

import NewClient from './new/client';
import { EventsContext } from '@context/events';
import { titleCase } from '@helpers/text';

export default function Index(props) {
  const { doAfterClientSignUp, doAfterPractitionerSignUp, defaultUserType } = props;
  const { user } = useContext(UserContext);
  const [userType, setUserType] = useState(null);
  const [isBackEnabled, setIsBackEnabled] = useState(false);
  const theme = useTheme();
  const { trackEvent } = useContext(EventsContext);
  var inviteToken = getCookie(COOKIE_NAMES.INVITE_TOKEN);

  function ButtonCard(props){
    const { onClick, title, icon, dataTestId } = props;
    return (
      <ButtonBase sx={{ textAlign: 'left' }} onClick={onClick} data-testid={dataTestId}>
        <Card sx={{ display: 'flex', width: '80%', color: theme.palette.braceGreenDark.main }} variant='outlined'>
          <Box sx={{ flexGrow: 1, padding: 1, paddingLeft: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box sx={{ 
              fontSize: 'h5.fontSize', 
              paddingBottom: 0.5,
              fontWeight: 'medium' }}>
              {title}
            </Box>
          </Box>
          <Box sx={{ padding: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Icon type={icon} size='10vh' delay="1000"/>
          </Box>
        </Card>
      </ButtonBase>
    );
    
  }

  useEffect(() => {
    if(user?.role && user?.role != 'PENDING'){
      setUserType(user?.role);
    }
  }, []);

  const handleTypeSelect = (type) => {
    trackEvent(`Onboarding:UserType:Selected:${titleCase(type)}`);
    setUserType(type);
    setIsBackEnabled(true);
  };

  const goBack = isBackEnabled ? 
    () => {
      setUserType(null);
    } : null;

  return(
    <Box sx={{ 
      background: '#fff', 
      height: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))',
      width: '100vw', 
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: theme.palette.braceGreenDark.main
    }}>
      <Box sx={{ p: 2, paddingTop: 8 }}>
        <Image 
          duration={500} 
          width='150px' 
          src='https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_Dark%20and%20grad.png'/>
      </Box>
      <Typography fontSize={'h4.fontSize'}
        fontWeight='medium'
        paddingTop={2}>
          Welcome to Brace!
      </Typography>
      {(inviteToken || userType == 'CLIENT') && 
        <NewClient doAfterSignUp={doAfterClientSignUp} goBack={goBack} />
      }
     
      {((!inviteToken && defaultUserType == 'PRACTITIONER') || userType == 'PRACTITIONER') && 
          <Practitioner doAfterSignUp={doAfterPractitionerSignUp} goBack={goBack}/>
      }
      {(!inviteToken && defaultUserType != 'PRACTITIONER' && !userType) &&
          <Box sx={{ p: 2, paddingBottom: 4, paddingTop: 4 }}>

            <Box sx={{ 
              fontSize: 'body1.fontSize', 
              textAlign: 'center'
            }}>
           Brace has two types of accounts, one for users who are Rehabilitating and one for our Practitioners.
            </Box>
            <Box sx={{ 
              p: 1,
              paddingTop: 4,
              fontSize: 'body1.fontSize', 
              textAlign: 'center'
            }}>
            What type of user are you?
            </Box>
            <Stack 
              direction="column"
              spacing={3}
            >
              <ButtonCard 
                dataTestId='index-sign-up-role-selection-client'
                onClick={() => handleTypeSelect('CLIENT')}
                title='Rehabilitating'
                icon='stretching'
              />
              <ButtonCard 
                dataTestId='index-sign-up-role-selection-professional'
                onClick={() => handleTypeSelect('PRACTITIONER')}
                title='Practitioner'
                icon='massage'
              />
             
            </Stack>
          </Box>
      }
    </Box>
  );
}