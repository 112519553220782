import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { 
  Box,
  Divider,
  Dialog
} from '@mui/material';

import { DialogHeader, DialogTransition } from '@components/atoms';
import TopicContent from './content';
import { UserContext, ServicesContext, EventsContext, AccountOnboardingContext } from '@context';
import { NewPostContext, Comments } from '@components/organisms';
import { useTheme } from '@emotion/react';

export default function ViewTopic(props){
  const { search } = useLocation();
  var theme = useTheme();
  const { user } = useContext(UserContext);
  var { accountOnboarding, loadAccountOnboarding } = useContext(AccountOnboardingContext);
  const { api } = useContext(ServicesContext);
  const { trackEvent } = useContext(EventsContext);
  const [topicId, setTopicId] = useState(null);
  const [topic, setTopic] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { openNewPostDialog } = useContext(NewPostContext);

  const loadTopic = async () => {
    setIsLoading(true);
    var result = await api.get({ url: `community/topics/${topicId}`, user });
    setTopic(result);
    setIsLoading(false);
  };

  const values = queryString.parse(search);
  useEffect(() => {
    if(values?.topicId){
      setTopicId(values.topicId);
    }else if(topicId){
      setTopicId(null);
    }
  }, [values]);

  useEffect(() => {
    if(topicId){
      loadTopic();
      trackEvent('Community:Topic:Open', { topicId });
    }else{
      setTopic(null);
    }
  }, [topicId]);

  const openCommentDialog = async (comment) => {
    trackEvent('Community:Topic:Comment:Open', { topicId });
    openNewPostDialog({ 
      title: 'New Topic', 
      placeholder: 'Your comment...', 
      buttonAction: async ({ message }) => {
        await api.post({ url: `community/topics/${topicId}/comments`, user, data: { message, parentCommentId: comment?.id } });
        trackEvent('Community:Topic:Comment:Save', { topicId });
        await loadTopic();

        if(!accountOnboarding?.hasContributedToCommunity){
          loadAccountOnboarding();
        }
      }, 
      buttonText: 'COMMENT',
      successToastMessage : 'Comment Created!',
      isImageEnabled: false
    });
  };

  return (
    <Dialog
      open={topicId != null}
      fullScreen
      TransitionComponent={DialogTransition}
      sx={{ ...props.sx, marginTop: 'env(safe-area-inset-top)' }} >
      <Box sx={{
        backgroundColor: theme.palette.background.paper, marginBottom: '1px'
      }} >
        <DialogHeader doHistoryBack={true}/>
        <Divider light/>
        <Box sx={{ px: 1, paddingTop: 1,  backgroundColor: theme.palette.background.paper }}>
          <TopicContent isLoading={isLoading} topic={topic} openCommentDialog={openCommentDialog}/>
        </Box>
        <Comments 
          openCreateComment={openCommentDialog}
          parentUser={topic?.user}
          commentCount={topic?.commentCount}
          emptyStateMessage='No one has commented on this topic yet. Be the first to share your thoughts!'
          loadCommentsUrl={`community/topics/${topic?.id}/comments`}
        />
      </Box>
    </Dialog>
  );
}

