import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { 
  Box,
  CircularProgress,
  Stack,
  Divider,
  Select,
  MenuItem
} from '@mui/material';
import ForumIcon from '@mui/icons-material/Forum';

import { ActionsButton, EmptyState } from '@components/molecules';
import { EventsContext } from '@context/events';
import { NewPostContext } from '@components/organisms';
import Topic from './topic';
import { ServicesContext } from '@context/services';
import { UserContext } from '@context/user';
import moment from 'moment';
import { CommunityFilterContext, CommunityFilters } from '@components/v2/organisms';

const EmptyLoadingState = () => {
  var rows = [];
  for (var i = 0; i < 10; i++) {
    rows.push(<Topic key={i} topic={{}} isLoading={true}/>);
  }
  return rows;
};

export default function TopicFeed() {
  const { trackEvent } = useContext(EventsContext);
  const { openNewPostDialog } = useContext(NewPostContext);
  const { initialize, sortBy, searchTerm } = useContext(CommunityFilterContext);
  const [topics, setTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [anchor, setAnchor] = useState(moment());
  const [moreResultsLeft, setMoreResultsLeft] = useState(true);
  const { api } = useContext(ServicesContext);
  const { getUserToken } = useContext(UserContext);

  useEffect(() => {
    initialize({ isSearchEnabled: true, defaultSortBy: 'POPULAR' });
    trackEvent('Community:Topics:Open');
  }, []);

  useEffect(() => {
    searchTopics([], 0);
  }, [sortBy, searchTerm ]);

  const openCreateDiscussion = async () => {
    trackEvent('Community:Topic:Create:Open');
    openNewPostDialog({ 
      title: 'New Discussion', 
      requiresTitle: true,
      placeholder: 'Your Discussion..', 
      buttonAction: async ({ message, title, image }) => {
        const formData = new FormData();
        formData.append('name', title);
        formData.append('description', message);
    
        if(image){
          formData.append('image', image);
        }
    
        var topic = await api.post({ url: 'community/topics', data: formData, getUserToken });
        trackEvent('Community:Topic:Create:Save');
        await handleNewTopic(topic);
        
      }, 
      successToastMessage : 'Topic Created!'
    });
  };

  const searchTopics = async (existingTopics, page) => {
    var params = {
      page,
      searchTerm,
      createdAtLimit: anchor.toISOString(),
      sortBy
    };

    var result = await api.get({ url: 'community/topics/search', params, getUserToken });
    setIsLoading(false);
    setMoreResultsLeft(result.results.length != 0);
    setTopics(existingTopics.concat(result.results));
  };

  const refresh = async () => {
    setAnchor(moment());
    setCurrentPage(0);
    setIsRefreshing(true);
    await searchTopics([], 0);
    setIsRefreshing(false);
  };

  const loadNextPage = async () => {
    setCurrentPage(currentPage + 1);
    await searchTopics(topics, currentPage + 1);
  };

  const handleComment = async (topicId) => {
    setTopics(
      topics.map(topic  =>{
        if(topic.id === topicId){
          topic.commentCount += 1;
          return topic;
        }else{
          return topic;
        }
      })
    );
  };

  const handleNewTopic = async (topic) => {
    setTopics([topic].concat(topics));
  };

  return (
    <Stack 
      direction="column" 
      sx={{ width: '100%' }}>
      <Divider/>
      <CommunityFilters />
      <Box>
        {(isLoading && topics.length === 0)  &&
          <EmptyLoadingState />
        }
        {(!isLoading && !isRefreshing && topics.length === 0 ) &&
      (
        <Box p={2}>
          <EmptyState iconType={'search' }
            title='No Matches Found' 
            message='Please try another search' />
        </Box> 
      )}
        {isRefreshing && 
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
          <CircularProgress size={'1.5rem'}/>
        </Box>
        }
        {!isLoading && topics.length > 0 &&
        <InfiniteScroll
          dataLength={topics.length}
          next={loadNextPage}
          hasMore={moreResultsLeft}
          loader={
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
              <CircularProgress size={'1.5rem'}/>
            </Box>
          }
          refreshFunction={refresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
        >
          {topics.map((topic) =>
            <Topic topic={topic}
              key={topic.id}
              isLoading={isLoading}
              handleComment={handleComment}/>     
          )}
        </InfiniteScroll>
        }

      </Box>
      <ActionsButton actions={[
        { icon: <ForumIcon />, name: 'Create Discussion', onClick: () => openCreateDiscussion() } 
      ]}/>
    </Stack>
  );
}