import React from 'react';

import { 
  Radio as MuiRadio
} from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';

export default function Radio(props){
  return (
    <MuiRadio 
      {...props}
      checkedIcon={<CheckCircle  />}
      icon={<RadioButtonUnchecked  />}
    /> 
  );
}