import React, { useState , useContext, useEffect } from 'react';

import {
  Box,
  Menu,
  MenuItem,
  Button,
  ListItemIcon,
  ListItemText,
  MenuList,
  Typography
} from '@mui/material';

import PublicIcon from '@mui/icons-material/Public';
import GroupsIcon from '@mui/icons-material/Groups';
import LockIcon from '@mui/icons-material/Lock';

import { NewPostContext } from './new-post-context';
import { UserContext } from '@context/user';

export default function Access() {
  const { activeOrganisation } = useContext(UserContext);
  const [ selected, setSelected ] = useState('PUBLIC');
  const { settings, setOrganisation, setTeam, setIsPrivate, isOpen } = useContext(NewPostContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if(!isOpen){
      setSelected('PUBLIC');
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  var options = [
    {
      id: 'PUBLIC',
      icon: <PublicIcon fontSize="small" />,
      text:  'Public',
      onClick: () => {
        setOrganisation(null);
        setTeam(null);
        setSelected('PUBLIC');
        setIsPrivate(false);
        handleClose();
      }
    }

  ];

  if(settings.isPrivateEnabled){
    options.push({
      id: 'PRIVATE',
      icon: <LockIcon fontSize="small" />,
      text:  'Only Me',
      onClick: () => {
        setOrganisation(null);
        setTeam(null);
        setSelected('PRIVATE');
        setIsPrivate(true);
        handleClose();
      }
    });
  }

  if(activeOrganisation?.id){
    options.push({
      id: 'ORGANISATION',
      icon: <GroupsIcon fontSize="small" />,
      text:  `${activeOrganisation.name} members`,
      onClick: () => {
        setOrganisation(activeOrganisation);
        setTeam(null);
        setIsPrivate(false);
        setSelected('ORGANISATION');
        handleClose();
      }
    });

    activeOrganisation.myTeams.map((team) => {
      options.push({
        id: `TEAM-${team.id}`,
        icon: <GroupsIcon fontSize="small" />,
        text:  `${team.name} members`,
        onClick: () => {
          setOrganisation(null);
          setTeam(team);
          setIsPrivate(false);
          setSelected(`TEAM-${team.id}`);
          handleClose();
        }
      });
    });
  }

  var activeOption = options.filter(o => o.id == selected)[0];

  return (
    <Box sx={{
      p: 1
    }} >
      <Typography variant='body2' color='text.secondary' data-testid='new-post-dialog-access-label'>
        Who Can See This?
      </Typography>
      <Button
        size='small'
        id="basic-button"
        data-testid='new-post-dialog-access-menu-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        startIcon={activeOption.icon}
        onClick={handleClick}
      >
        <>{activeOption.text}</>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuList dense>
          {options.map((option) => (
            <MenuItem key={option.id} onClick={option.onClick}  data-testid={option.id}>
              <ListItemIcon>
                {option.icon}
              </ListItemIcon>
              <ListItemText>{option.text}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
}