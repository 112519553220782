import React, { createContext, useContext, useEffect, useState  } from 'react';
import mixpanel from 'mixpanel-browser';
import { ServicesContext } from './services';
import { ColorModeContext } from './theme';
import { useAuth0 } from '@auth0/auth0-react';
import { COOKIE_NAMES, getCookie } from './../helpers/cookies';
import config from '@config';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';

export const UserContext = createContext();

export function UserProvider(props){
  const STORAGE_KEY = 'user';
  const { isMobileApp, loadUser, openSuccessModal } = props;
  const { storage, api, logger } = useContext(ServicesContext);
  const { clearColorMode } = useContext(ColorModeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [organisations, setOrganisations] = useState([]);
  const [activeOrganisation, setActiveOrganisation] = useState(null);
  const [activeOrganisationAccount, setActiveOrganisationAccount] = useState(null);
  var dispatch;

  if(loadUser || openSuccessModal){
    dispatch = useDispatch();
  }

  const {
    isLoading: auth0IsLoading,
    user: auth0User,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
    error
  } = useAuth0();

  useEffect(() => {
    if(error){
      logger.error('Auth0 Error', error);
    }
  }, [error]);

  useEffect(() => {
    logger.info('Initiating User Context');
  }, []);

  useEffect(() => {
    if(auth0User && isAuthenticated){
      refresh();
    }
  }, [auth0User]);

  useEffect(() => {
    if(!auth0IsLoading && !auth0User && !isAuthenticated){
      setIsLoading(false);
    }
  }, [auth0IsLoading]);

  useEffect(() => {
    if(user){
      mixpanel.identify(user.id);
      mixpanel.people.set({ '$email': user.email });
      mixpanel.people.set({ '$name': user.name });
      setIsLoading(true);
      api.post({ url: 'organisations/search', user: user })
        .then((response) => {  
          setOrganisations(response.results);
          
          if(response.results.length > 0){
            // Going to assume only on organization for now. This might change
            var o = response.results[0];
            setActiveOrganisation(o);
          }else if(activeOrganisation?.id){
            setActiveOrganisation(null);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });

      if(config.isMobileApp){
        updateUserMessageToken();
      }
    }
  }, [user]);

  useEffect(() => {
    if(activeOrganisation){
      setIsLoading(true);
      api.get({ url: `organisations/${activeOrganisation.id}/accounts/${user.id}`, user: user })
        .then((response) => {  
          setActiveOrganisationAccount(response);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [activeOrganisation]);

  const handleLogout = (callback) => {
    storage.delete(STORAGE_KEY);
    clearColorMode();
    setUser(null);
    setOrganisations([]);
    setActiveOrganisation(null);
    setActiveOrganisationAccount(null);
    logout({ onRedirect: () => {} });
    if(callback && typeof callback === 'function')
      callback();
  };

  const handleAuth0Error = (e) => {
    logger.error(`Auth0 error ${e.message}`, error);
    if(e.error == 'invalid_grant'){
      handleLogout(() => {window.location = '/';});
    }
  };

  const refresh = () => {
    logger.info('Refreshing User Context');
    setIsLoading(true);
    getAccessTokenSilently()
      .then(t => {
        api.get({ url: 'users/me', token : `Bearer ${t}` })
          .then((refreshedUser) => {  
            setToken(t);
            refreshedUser.token = `Bearer ${t}`;
            storage.save(STORAGE_KEY, refreshedUser);
            setUser(refreshedUser);
            if(loadUser){
              dispatch(loadUser());
            }
            
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        handleAuth0Error(err);
      });
  };

  const handleSaveImage = async (image) => {
    const formData = new FormData();
    formData.append('file', image);
  
    var saveImageResult = await api.put({ url: 'users/image', data: formData, user });

    setUser(
      {
        ...user,
        userImageModified: new Date().getTime(),
        imageLocation: saveImageResult.imageLocation
      }
    );

    return Promise.resolve();

  };

  const handleUpdate = async () => {
    refresh();
  };

  const handleActivate = async (name, role) => {
    var inviteToken = getCookie(COOKIE_NAMES.INVITE_TOKEN);
    await api.put({ url: 'accounts/activate', user, data: { name, role, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, inviteToken } });
    refresh();
  };

  var getToken = function(saveTokenCB){
    FirebasePlugin.getToken(function(token){
      saveTokenCB(token);
      onTokenRefresh(saveTokenCB);
    }, function(error) {
    });
  };
  
  var onTokenRefresh = function(saveTokenCB){
    FirebasePlugin.onTokenRefresh(function(token) {
      saveTokenCB(token);
    }, function(error) {
    });
  };

  var checkNotificationPermission = function(requested, saveTokenCB){
    if(typeof FirebasePlugin !== 'undefined'){
      FirebasePlugin.hasPermission(function(hasPermission){
        if(hasPermission){
          getToken(saveTokenCB);
        // Granted
        }else if(!requested){
        // Request permission
          FirebasePlugin.grantPermission(checkNotificationPermission.bind(this, true));
        }else{
        // Denied
        }
      });
    }
  };

  const updateUserMessageToken = () => {
    try{
      logger.info('Refreshing User Message Token');
      checkNotificationPermission(false, async (token) => {
        await api.put({ url: 'users/messageToken', user, data: { messageToken: token } });
      });
    }
    catch(e){
      logger.error('Failed Refreshing User Message Token', e);
    }
  };

  const getUserToken = async () => {
    if(!token){
      return null;
    }

    var result = token;

    if(jwtDecode(token).exp * 1000 < new Date().getTime()){
      logger.info('Forcing User Token Refresh');
      try{
        result = await getAccessTokenSilently();
        setToken(result);
        user.token = `Bearer ${token}`;
        storage.save(STORAGE_KEY, user);
        setUser(user);
        if(loadUser){
          dispatch(loadUser());
        }
      }catch(err){
        handleAuth0Error(err);
      }

    }
    
    return `Bearer ${result}`;
  };

  return (
    <UserContext.Provider value={{ 
      isMobileApp,
      user, 
      auth0User,
      organisations, 
      isAuthenticated,
      activeOrganisation, 
      activeOrganisationAccount, 
      isLoading: isLoading || auth0IsLoading, 
      handleActivate,
      handleLogout, 
      handleSaveImage,
      handleUpdate,
      refresh,
      getUserToken
    }}>
      {props.children}
    </UserContext.Provider>
  );
}