import Purchases, { LOG_LEVEL } from 'cordova-plugin-purchases/www/plugin';

const mapCustomerInfoToSubscription = (customerInfo) => {
  if(customerInfo?.entitlements?.active && Object.keys(customerInfo?.entitlements?.active).length > 0){
    var entitlement = customerInfo?.entitlements?.active[Object.keys(customerInfo?.entitlements?.active)[0]];
    var expiresAt = entitlement?.expirationDate;
    var billingIssueDetectedAt = entitlement?.billingIssueDetectedAt;
    var unsubscribedAt = entitlement.unsubscribeDetectedAt;
    var store = entitlement?.store;
    var managementUrl = customerInfo.managementURL;
    
    return { expiresAt, unsubscribedAt, billingIssueDetectedAt, store, managementUrl };
  }

  return null;
};

export default class Payments {
  constructor(config, logger) {
    this.config = config;
    this.logger = logger;
  }
  isAvailable(){
    return this.config.isMobileApp && typeof Purchases !== 'undefined';
  }
  init(user, cb){
    if(this.isAvailable()){
      try{
        this.logger.info('Configuring Payment Plugin');
        Purchases.setLogLevel(LOG_LEVEL.DEBUG);
        Purchases.configureWith({
          apiKey: window.cordova.platformId == 'android' ?
            'goog_NvQTtzPUcfiiTbEfPifvNkRxBtR' : 
            'appl_pweIdpjorNNvfxrUhTxvjsBjXaT',
          appUserID: user.id
        });
        Purchases.setEmail(user.email);
        if(cb){
          cb();
        }
      }catch(e){
        this.logger.error('Failed to initPaymentPlugin', e);
      }
    }
  }
  getCustomerInfo(cb){
    this.logger.info('Loading Customer Info');
    if(this.isAvailable()){
      try{
        Purchases.getCustomerInfo(
          customerInfo => {
            try{
              cb(mapCustomerInfoToSubscription(customerInfo));
            }catch(e){
              this.logger.error('Failed to parse getCustomerInfo', e);
            }
          },
          error => {
            this.logger.error('Failed to getCustomerInfo', error);
          }
        );
      }catch(e){
        this.logger.error('Failed to getCustomerInfo', e);
      }
    }else{
      this.logger.warn('Payment plugin is not available so cannot getCustomerInfo');
    }
  }
  getOfferings(cb){
    if(this.isAvailable()){
      this.logger.info('Loading Offering');
      try{
        Purchases.getOfferings(
          offerings => {
            try{
              
              var result = [];
              
              offerings.current.availablePackages.forEach(o => {
                result.push({
                  id: o.identifier,
                  rcOffering: o,
                  period: o.product.subscriptionPeriod,
                  price: o.product.priceString
              
                });
              });

              cb(result);
            }
            catch(e){
              this.logger.error('Failed to parse offerings', e);
            }
          },
          error => {
            this.logger.error('Failed to getOfferings', error);
          }
        );
      }
      catch(e){
        this.logger.error('Failed to getOfferings', e);
      }
    }else{
      this.logger.warn('Payment plugin is not available so cannot getOfferings');
    }
  }
  purchasePackage(p, cb) {
    if(this.isAvailable()){
      this.logger.info(`Purchasing Package - ${p.identifier}`);

      try{
        Purchases.purchasePackage(p,
          result => {
            try{
              this.logger.info('Purchasing Package Complete');
              cb(mapCustomerInfoToSubscription(result.customerInfo));
            }
            catch(e){
              this.logger.error('Failed to parse offerings', e);
            }
            
          },
          error => {
            this.logger.error('Failed to purchasePackage', error);
            cb(null);
          });
      }
      catch(e){
        this.logger.error('Failed to purchasePackage', e);
      }
      
    }else{
      this.logger.warn('Payment plugin is not available so cannot purchasePackage');
    }
  }
}
