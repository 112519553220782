import React from 'react';

import {
  Box
}from '@mui/material';

import { PostFeed } from '@components/organisms';

export default function Posts(props) {
  var { searchValue } = props;

  return (
    <Box sx={{ width: '100%' }}>
      <PostFeed searchValue={searchValue} showCreateCheckInFab={true} areQuickFilersEnabled={true}/>
    </Box>
  );
  
}