import React from 'react';

import { styled } from '@mui/material/styles';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { 
  Box,
  Typography
} from '@mui/material';

export default function ProgressLinear(props) {
  const { value, text, height = 10 } = props;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorSecondary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: value == 100 ? theme.palette.primary.main : theme.palette.secondary.light
    }
  }));

  return (
    <Box sx={{ position: 'relative' }}>
      <BorderLinearProgress 
        variant="determinate"
        value={value} 
        color={value === 100 ? 'primary' : 'secondary'}  />
      <Typography
        sx={{
          color: value == 100 ? '#fff' : 'text.primary',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)'
          
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}

{/* <div style={{ position: "relative" }}>
<LinearProgress
  className={classes.root}
  variant="determinate"
  value={100 * (progress / currentMaxTimer)}
  {...props}
/>
<Typography
  style={{
    position: "absolute",
    color: "white",
    top: 0,
    left: "5%",
    transform: "translateX(-50%)",
  }}
>
  {progressTxt}
</Typography>
<Typography
  style={{
    position: "absolute",
    color: "white",
    top: 0,
    left: "95%",
    transform: "translateX(-50%)",
  }}
>
  {currentMaxTimerTxt}
</Typography>
</div> */}