import React from 'react';

import {
  Box,
  Typography 
} from '@mui/material';

import { toShortDateString } from '@helpers/dates';
import LoadableText from './loadable/text';

export default function UserGoalText(props) {
  const { goal, isLoading } = props;
  return (
    <Typography sx={{ mx: 1 }} component='div'>
      <LoadableText isLoading={isLoading}
        text={
          <Box sx={{ display: 'flex' }}>
            <Typography fontWeight='medium'>{goal.description}</Typography> 
            {goal.targetDate &&
              <>
              &nbsp;by&nbsp;
                <Typography fontWeight='medium'> {toShortDateString(goal.targetDate)}</Typography>
              </>
            }
          </Box>}
      />
     
    </Typography>
  );
}