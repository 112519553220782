import React from 'react';

import {
  Badge,
  Box
}from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';

import Avatar from './../atoms/avatar';
import { useTheme } from '@emotion/react';

import config from '@config';

export default function ProfileImage(props) {
  const { size = 36, icon, iconSize, iconType='person', dataTestId, onClick, user, profile, badgeCount = 0, lastMod, isThisUser, name, text, isLoading, linkTo, isPro: isProProp } = props;
  const theme = useTheme();

  var id = user?.id ?
    user.id :
    profile?.id;

  var doesUserHaveImage = true;

  if(user?.hasImage == false){
    doesUserHaveImage = false;
  }

  var src = id && doesUserHaveImage ? `${config.profileImagesBaseUrl}images/user-profile-image-${id}` : '';

  if(isThisUser && doesUserHaveImage){
    src = `${src}?lastmod=${lastMod}`;
  }

  var isPro = isProProp;

  if(!isPro){
    isPro = user?.isAccountOnboardingComplete || profile?.isAccountOnboardingComplete;
  }

  var proIconSize = size / 2.5;

  if(proIconSize > 25){
    proIconSize = 25;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Badge 
        badgeContent={badgeCount}
        color="secondary"
        variant="dot"
        sx={{ position: 'relative' }}>
        <Avatar 
          isLoading={isLoading}
          linkTo={linkTo}
          size={size}
          name={name}
          src={src}
          onClick={onClick}
          dataTestId={dataTestId}
          sx={{ width: size, height: size, borderRadius: 1, backgroundColor: theme.palette.background.paper }}
          imgProps={{ loading: 'lazy', decoding:'async' }}
          iconSize={iconSize}
          iconType={iconType}
          text={text}
        >
          {icon}
        </Avatar>
      </Badge>
      {isPro &&
        <Box sx={{ position: 'absolute', bottom: -(proIconSize / 4), right: -(proIconSize / 4), lineHeight: 1 }}>
          <Box sx={{ 
            backgroundColor: theme.palette.braceGreenLight.main, 
            borderRadius: '50%',
            width: proIconSize,
            height: proIconSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <StarRateIcon sx={{ fontSize: proIconSize * 0.8 }}/>
          </Box>
        
        </Box>
      }
    </Box>
  );
}