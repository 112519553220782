import React from 'react';

import { 
  Box,
  TextField

} from '@mui/material';

import { 
  LocalizationProvider,
  DatePicker
} from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';

import { CountrySelect } from '@components/atoms';
import 'moment/locale/en-gb';

export default function Step1(props) {
  const { formik } = props;

  return (
    <Box sx={{ height: '100%', minHeight: '300px', width:'100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ 
        fontSize: 'body1.fontSize', 
        textAlign: 'center',
        fontWeight: 'regular'
      }}>
        Lets’s start with some account details.
      </Box>
      <Box sx={{ flexGrow: 1, p : 3, py: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
        <TextField 
          inputProps={{ 'data-testid': 'index-sign-up-text-first-name' }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          size='small'
          label='What is your first name?'
          id='firstName'
          required
          onChange={formik.handleChange}
          value={formik.values.firstName}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          autoComplete='given-name'
        />
        <TextField 
          inputProps={{ 'data-testid': 'index-sign-up-text-last-name' }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          size='small'
          label='What is your last name?'
          id='lastName'
          required
          onChange={formik.handleChange}
          value={formik.values.lastName}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          autoComplete='family-name'
        />
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
          <DatePicker
            disableFuture
            inputProps={{ 'data-testid': 'index-sign-up-text-date-of-birth' }} 
            required
            label='What is your date of birth? '
            size='small'
            id='dateOfBirth'
            value={formik.values.dateOfBirth}
            onChange={(value) => {
              formik.setFieldValue('dateOfBirth', value ?  value.toISOString() : null, true);
            }}
            renderInput={(params) => <TextField  
  
              InputLabelProps={{ shrink: true }}
              error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
              helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
              required
              onBlur={() => {
                formik.setFieldTouched('dateOfBirth', true);
              }}
              {...params} 
              size='small'/>}
          />
        </LocalizationProvider>

        <CountrySelect
          InputLabelProps={{ shrink: true }}
          fullWidth={true}
          handleChange={(value) => {formik.setFieldValue('country', value);}}
          value={formik.values.country}
          touched={formik.touched.country}
          error={formik.errors.country}/>
      </Box>
    </Box>
  );
}