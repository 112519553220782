import React, { useContext } from 'react';

import {
  Box 
} from '@mui/material';

import { NewPostInputContext } from './new-post-input-context';

import ImageIcon from '@mui/icons-material/Image';

import { ImageUploader } from '@components/molecules';

export default function Image() {
  const { setImage } = useContext(NewPostInputContext);

  return (
    <Box sx={{ marginBottom: 'env(safe-area-inset-bottom)', p: 1 }}>
      <ImageUploader imageId='NewPostImage' isEnabled={true} saveImage={setImage}>
        <ImageIcon fontSize="large" color={'primary'}/>
      </ImageUploader>
    </Box>
  );
}