
class AppConfig {
  constructor(){
    this.version = VERSION;
    this.isMobileApp = false;
    this.isWebApp = true;
    this.consoleLoggingEnabled = false;
    this.apiBaseUrl= 'https://bracesocial-prod-api.azurewebsites.net/api/',
    this.contentBaseUrl= 'https://bracesocialprod.blob.core.windows.net/';
    this.appInsightsKey = 'InstrumentationKey=c15741cf-06f4-4f6a-8dc5-cc9f98998401;IngestionEndpoint=https://northeurope-0.in.applicationinsights.azure.com/';
    this.mixPanelKey = '733a09f9b4b866b3ead09da12f1b4e9f';
    this.auth0Domain = 'auth.bracesocial.com';
    this.auth0Audience = 'https://bracesocial.eu.auth0.com/api/v2/';
    this.auth0ClientId = 'UCHLNlE3VvoUQg0VmLb31ccJ1r6txERY';
    this.statsigKey = 'client-ivyIoi4G4hAdRbM6aPSSFvIaDCBcqSc1gFj6zz7143i';
    this.consoleLoggingEnabled = false;
    this.logLevel = 0;
    this.environment = 'production';
  }
} 

export default new AppConfig();