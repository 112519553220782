import { ServicesContext } from '@context/services';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { UserContext, AccountOnboardingContext } from '@context';
import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { EventsContext } from '@context/events';
import { CommunityFilterContext } from '@components/v2/organisms';

export const PostFeedContext = createContext();

export function PostFeedProvider(props){
  var { children } = props;
  var { user, getUserToken } = useContext(UserContext);
  var { accountOnboarding, loadAccountOnboarding } = useContext(AccountOnboardingContext);
  var { initialize, userId, searchTerm, sortBy } = useContext(CommunityFilterContext);
  var { api } = useContext(ServicesContext);
  var [currentPage, setCurrentPage] = useState(0);
  var [anchor, setAnchor] = useState(moment());
  var [posts, setPosts] = useState([]);
  var [isLoading, setIsLoading] = useState(false);
  var [hasFinishedInitialLoad, setHasFinishedInitialLoad] = useState(false);
  var [isRefreshing, setIsRefreshing] = useState(false);
  var [moreResultsLeft, setMoreResultsLeft] = useState(true);
  var [postBeingViewed, setPostBeingViewed] = useState(null);
  var [isJustMe, setIsJustMe] = useState(false);
  const { trackEvent } = useContext(EventsContext);

  const { search } = useLocation();
  const values = queryString.parse(search);

  const searchPosts = async (existingPosts, page) => {
    var params = {
      page,
      createdAtLimit: anchor.toISOString(),
      searchTerm: searchTerm,
      sortBy: sortBy
    };

    if(userId){
      params.userId= userId;
    }

    // if(searchValue.activeOrganisationFilter?.id){
    //   params.organisationId=searchValue.activeOrganisationFilter?.id;
    // }

    // if(searchValue.activeTeamFilter?.id){
    //   params.teamId=searchValue.activeTeamFilter?.id;
    // }

    if(isJustMe){
      params.userId=user.id;
    }

    var result = await api.get({ url: 'community/posts/search', params, user, getUserToken });
    setIsLoading(false);
    setHasFinishedInitialLoad(true);
    setMoreResultsLeft(result.results.length != 0);
    setPosts(existingPosts.concat(result.results));
  };

  const refresh = async () => {
    setAnchor(moment());
    setCurrentPage(0);
    setIsRefreshing(true);
    await searchPosts([], 0);
    setIsRefreshing(false);
  };

  const loadNextPage = async () => {
    setCurrentPage(currentPage + 1);
    await searchPosts(posts, currentPage + 1);
  };

  const savePost = async (data) => {
    const formData = new FormData();
    formData.append('message', data.message);
    formData.append('private', data.isPrivate);

    if(data.image){
      formData.append('image', data.image);
    }

    if(data.organisation){
      formData.append('organisationId', data.organisation.id);
    }

    if(data.team){
      var teamIds = [ data.team.id ];
      formData.append('teamIds', teamIds);
    }

    var post = await api.post({ data: formData, url: 'community/posts', user });
    trackEvent('Community:Posts:Save');
    setPosts([post, ...posts]);

  };

  const deletePost = async (postId) => {
    trackEvent('Community:Posts:Delete', { postId });
    await api.delete({ url: `community/posts/${postId}`, user });
    await refresh();
  };

  const likePost = async (postId) => {
    await api.put({ url: `community/posts/${postId}/like`, user });
    trackEvent('Community:Posts:Like', { postId });
    if(!accountOnboarding?.hasContributedToCommunity){
      loadAccountOnboarding();
    }
    setPosts(
      posts.map(post  =>{
        if(post.id === postId){
          post.likedByCurrentUser = true;
          post.likeCount += 1;
          return post;
        }else{
          return post;
        }
      })
    );
  };

  const likeComment = async (postId, commentId) => {
    if(postBeingViewed?.commentCount > 0 && !isLoading){
      await api.get({ url: `/api/community/comments/${commentId}/like`, user });
      trackEvent('Community:Posts:Comment:Like', { postId, commentId });
    }
    setIsLoading(false);
    if(!accountOnboarding?.hasContributedToCommunity){
      loadAccountOnboarding();
    }
  };

  const saveComment = async ({ postId, parentCommentId, message }) => {
    await api.post({ url: `community/posts/${postId}/comments`, data:{ message, parentCommentId }, user });
    trackEvent('Community:Posts:Comment:Save', { postId });
    if(postBeingViewed?.id == postId){
      setPostBeingViewed({
        ...postBeingViewed,
        commentCount: postBeingViewed.commentCount + 1
      });
    }
    setPosts(
      posts.map(post  =>{
        if(post.id === postId){
          post.commentCount += 1;
          return post;
        }else{
          return post;
        }
      })
    );

    if(!accountOnboarding?.hasContributedToCommunity){
      loadAccountOnboarding();
    }
  };

  useEffect(() => {
    if(user?.id){
      initialize({ isSearchEnabled: true, defaultSortBy:'LATEST' });
      setIsLoading(true);
      setAnchor(moment());
      setPosts([]);
      setCurrentPage(0);
      searchPosts([], 0);
    }
  }, [user]);

  useEffect(() => {
    if(user?.id && hasFinishedInitialLoad){
      setIsLoading(true);
      setAnchor(moment());
      setPosts([]);
      setCurrentPage(0);
      searchPosts([], 0);
    }
  }, [searchTerm, sortBy]);

  useEffect(() => {
    if(isJustMe){
      trackEvent('Community:Posts:Filter:JustMe');
    }

    if(user?.id && hasFinishedInitialLoad){
      setIsLoading(true);
      setAnchor(moment());
      setPosts([]);
      setCurrentPage(0);
      searchPosts([], 0);
    }
  }, [isJustMe]);

  useEffect(() => {
    if(values.checkInId && user?.id){
      var post = posts.filter(p => p.id === values.checkInId)[0];

      if(post?.id){
        setPostBeingViewed(post);
      }else{
        api.get({ url: `community/posts/${values.checkInId}`, user })
          .then(p => setPostBeingViewed(p));
      }
    }else if(postBeingViewed?.id){
      setPostBeingViewed(null);
    }
    
  }, [values?.checkInId]);

  return (  
    <PostFeedContext.Provider value={{ 
      posts,
      refresh,
      loadNextPage,
      isLoading,
      isRefreshing,
      savePost,
      deletePost,
      likePost,
      saveComment,
      likeComment,
      moreResultsLeft,
      isViewingPost: values?.checkInId != null,
      values,
      postBeingViewed, 
      setPostBeingViewed,
      isJustMe, 
      setIsJustMe
    }}>
      {children}
    </PostFeedContext.Provider>
  );
}