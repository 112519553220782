import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { 
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { setCookie, COOKIE_NAMES } from './../../../helpers/cookies';

import config from '@config';
import { useContext } from 'react';
import { UserContext } from '@context';

import Onboarding from './onboarding';
import { LoadingPage } from '@components/molecules';
import { useTheme } from '@emotion/react';
import { forceLightTheme } from '@helpers/theme';
import { EventsContext } from '@context/events';

export default function Index(props) {
  var theme = useTheme();
  let { inviteToken } = useParams();
  const [hasShownLogin, setHasShownLogin] = useState(false);
  const { doAfterClientSignUp, doAfterPractitionerSignUp, defaultUserType } = props;
  const { loginWithRedirect, handleRedirectCallback } = useAuth0();
  const { user, isLoading, isAuthenticated, handleLogout } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);

  useEffect(() => {
    trackEvent('Index:Open');
  }, []);

  function openInBrowser(url) {
    if(config.isMobileApp){
      SafariViewController.isAvailable(function (available) {
        if (available) {
          SafariViewController.show({
            url: url
          },
          function(result) {},
          function(msg) {
            console.log('KO: ' + JSON.stringify(msg));
          });
        } else {
          window.open(url, '_system');
        }
      });
    }else{
      window.open(url, '_system');
    }
  }

  window.handleOpenURL = url => {
    if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
      handleRedirectCallback(url);
    }
    if(config.isMobileApp){
      SafariViewController.hide();
    }
  };

  const login = () => {
    if(inviteToken){
      setCookie({ name: COOKIE_NAMES.INVITE_TOKEN , value: inviteToken, minutes: 1 });
    }
    config.isMobileApp ?
      loginWithRedirect({
        openUrl: url => {
          try {
            openInBrowser(url);
          } catch (err) {
            console.log(err);
            SafariViewController.hide();
          }
        }

      }) : 
      loginWithRedirect();
  };

  if(!isLoading && !isAuthenticated || !user?.id){
    if(!hasShownLogin){
      setHasShownLogin(true);
      trackEvent('Index:Authentication:Open');
      login();
    }
  }

  if(user?.status === 'PENDING'){
    return (
      <ThemeProvider theme={forceLightTheme(theme)}>
        <Onboarding
          doAfterClientSignUp={doAfterClientSignUp}
          doAfterPractitionerSignUp={doAfterPractitionerSignUp}
          defaultUserType={defaultUserType}/>
      </ThemeProvider>
    );
  }

  return(
    <ThemeProvider theme={forceLightTheme(theme)}>
      <LoadingPage onClick={handleLogout}/>
    </ThemeProvider>
  );
}