import React, { useContext, useState } from 'react';

import { 
  Box,
  InputBase
} from '@mui/material';
import { Search, Tune } from '@material-ui/icons';
import { useTheme } from '@emotion/react';

import { CommunityFilterContext } from './context';
import CommunityFiltersDialog from './filters-dialog';

export default function CommunityFilters(){
  const theme = useTheme();
  const { isSearchEnabled, handleSearchTermChange, setIsFiltersDialogOpen } = useContext(CommunityFilterContext);
  const [value, setValue] = useState('');
  const [valueChangeTimeout, setValueChangeTimeout] = useState('');

  if(!isSearchEnabled){
    return <></>;
  }

  const handleChange = (newValue) => {
    setValue(newValue);
    if(valueChangeTimeout){
      clearTimeout(valueChangeTimeout);
    }
    setValueChangeTimeout(setTimeout(() => {
      handleSearchTermChange(newValue);
    }, 500));
    
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ 
        flexGrow: 1,
        borderRadius: 1, 
        backgroundColor: theme.palette.divider,
        display:'flex', 
        alignItems: 'center',
        height: '36px',
        px: 1
      }}>
        <Search />
        <InputBase
          disabled={!isSearchEnabled}
          sx={{ color: 'text.primary', flexGrow: 1, px: 1 }}
          placeholder="Quick Search"
          value={value}
          onChange={(event) => handleChange(event.target.value)} />
        <Tune onClick={() => setIsFiltersDialogOpen(true)}/>
      </Box>
      <CommunityFiltersDialog />
    </Box>
    
  );
}