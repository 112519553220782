import * as React from 'react';

import { CssBaseline, darkScrollbar } from '@mui/material';

import { teal, indigo, grey } from '@material-ui/core/colors';

import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export {
  ColorModeContext
};

const COLOR_MODE_STORAGE_KEY = 'color_mode';

export default function ToggleColorMode(props) {
  var { children, shouldForceLight } = props;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = React.useState('light');

  const calculateColorMode = () => {
    var getColorMode = localStorage.getItem(COLOR_MODE_STORAGE_KEY);
    if(getColorMode){
      return getColorMode;
    }
    return (prefersDarkMode && !shouldForceLight ) ? 'dark' : 'light';
  };

  const toggleColorMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    localStorage.setItem(COLOR_MODE_STORAGE_KEY, newMode);
    setMode(newMode);
  };

  const clearColorMode = () => {
    localStorage.removeItem(COLOR_MODE_STORAGE_KEY);
  };

  React.useEffect(() => {
    setMode(calculateColorMode());
  }, [prefersDarkMode]);

  var buttonColor = {
    main: mode === 'light' ? 'rgba(0, 0, 0, 0.54)' : grey[100],
    light: mode === 'light' ? grey[900] : grey[100],
    dark: mode === 'light' ? grey[900] : grey[100],
    contrastText: mode === 'light' ? '#fff' : '#111'
  };

  var theme = React.useMemo(
    () =>
      createTheme({
        components: {
          MuiCssBaseline: {
            styleOverrides: () => ({
              html: {
                ...darkScrollbar(
                  mode === 'light'
                    ? {
                      track: grey[200],
                      thumb: grey[400],
                      active: grey[400]
                    }
                    : undefined
                ),
                scrollbarWidth: 'thin'
              }
            })
          }
        },
        palette: {
          mode,
          primary: teal,
          secondary: indigo,
          button: buttonColor,
          braceGreenDark: {
            contrastText: '#fff',
            light: '#336569',
            main: '#003F43',
            dark: '#003236'
          },
          braceGreenLight: {
            main: '#00E39A',
            light: '#1ae6a4',
            dark: '#00cc8b',
            contrastText: '#fff'
          }
        }
      }),
    [mode]
  );

  theme = responsiveFontSizes(theme);

  return (
    <ColorModeContext.Provider value={{ toggleColorMode, clearColorMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}