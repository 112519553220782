import React from 'react';

import { 
  Slide,
  Dialog as MuiDialog,
  Box,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { Close } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function Dialog(props){
  var { 
    children, 
    isOpen = false, 
    close, 
    direction = 'up', 
    variant='fixed-bottom',
    title
  } = props;

  return (
    <MuiDialog 
      TransitionComponent={Transition}
      TransitionProps={{ direction }}
      onClose={close}
      sx={{ 
        '.MuiDialog-paper': { 
          position: 'fixed', 
          bottom: 0, 
          width: '100%',
          marginBottom: 0,
          paddingBottom: 'env(safe-area-inset-bottom)'
        } }}
      open={isOpen}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
        <DialogTitle sx={{ flexGrow: 1, fontSize: 'h4.fontSize' }}>{title}</DialogTitle>
      </Box>
      {children}
    </MuiDialog>
    
  );
}