import React, { useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { 
  Dialog,
  DialogTitle,
  Slide,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box, 
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography,
  Divider
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';
import { useTheme } from '@mui/material/styles';

import { AccountRoles } from '@helpers/roles';
import { LoadingButton } from '@mui/lab';
import config from '@config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddUser(props) {
  const { isOpen, close, accountRoleOptions = [], defaultAccountRole = 'CLIENT', title='Add User', confirmButtonText='Add' } = props;
  const { user, activeOrganisation } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const theme  = useTheme();
  const { api, logger } = useContext(ServicesContext);
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [role, setRole] = React.useState(defaultAccountRole);
  const [isSaving, setIsSaving] = React.useState(false);
  
  function handleSubmit(event) {
    event.preventDefault();
    setIsSaving(true);
    api.post({ url: `organisations/${activeOrganisation.id}/accounts`, data: { email, role, name }, user })
      .then(() => {
        enqueueSnackbar(`Successfully created ${name}`, { 
          variant: 'success'
        });
        close(true);
        setIsSaving(false);
      })
      .catch((err) => {
        logger.error(err);
        logger.error('Failed to create user', err);
        if(err?.data?.message){
          enqueueSnackbar(err.data.message, { 
            variant: 'error'
          });
        }else{
          enqueueSnackbar('Create user failed', { 
            variant: 'error'
          });
        }
       
        setIsSaving(false);
      });

  }

  useEffect(() => {
    setEmail('');
    setName('');
    setRole(defaultAccountRole);
    if(config.isTestingEnabled){
      setEmail(`clintonsweetnam${new Date().getTime()}@hotmail.com`);
      setName('Clinton Sweetnam');
    }
  }, [isOpen]);

  var roleOptions = accountRoleOptions.length > 0 ? 
    Object.keys(AccountRoles).filter(o => accountRoleOptions.filter(aro => aro.toUpperCase() == o.toUpperCase()).length > 0):
    Object.keys(AccountRoles);

  return(
    <Dialog 
      sx={{
        '.MuiPaper-root' : {
          [theme.breakpoints.down('md')]: {
            width: '90%'
          },
          [theme.breakpoints.up('md')]: {
            width: '50%'
          }
        }
      }}
      TransitionComponent={Transition}
      open={isOpen}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <DialogTitle data-testid='users-add-dialog-title'>{title}</DialogTitle>
        {(activeOrganisation.inviteUrl && roleOptions.filter(ro => ro == 'CLIENT').length > 0) &&
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography gutterBottom fontWeight='bold'>
                    Share This Link With Clients
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                      <Button size='small'
                        startIcon={<ContentCopyIcon fontSize='small'/>}
                        variant='contained'
                        color='secondary'
                        onClick={() => {
                          if(config.isMobileApp){
                            cordova.plugins.clipboard.copy(activeOrganisation.inviteUrl);
                          }else{
                            navigator.clipboard.writeText(activeOrganisation.inviteUrl);
                          }
                          enqueueSnackbar('Invite link copied!', { 
                            variant: 'info'
                          });
                        }}>
                            Copy Link
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ py: 1, width: '100%' }}>
                  <Divider>OR</Divider>
                </Box>
                <Typography gutterBottom  fontWeight='bold' sx={{ textAlign: 'center' }}>
                  Add Via Email Address
                </Typography>
              </>
        }
       
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={1}>
              <TextField
                inputProps={{ 'data-testid': 'users-add-text-name' }}
                required
                autoFocus
                size='small'
                margin="dense"
                id="name"
                label="Name"
                type="text"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                fullWidth
              />
              <TextField
                inputProps={{ 'data-testid': 'users-add-text-email' }}
                required
                size='small'
                margin="dense"
                id="email"
                label="Email"
                type="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                fullWidth
              />
              {roleOptions.length > 1 &&
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={role}
                    label="Role"
                    onChange={(event) => {setRole(event.target.value);}}
                  >
                    {
                      roleOptions.map((role) => 
                        <MenuItem key={role} value={role}>{AccountRoles[role]}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
              }
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => close(false)}>Cancel</Button>
            <LoadingButton data-testid= 'users-add-submit-button'
              loading={isSaving}
              disabled={email == '' || name == ''}
              variant='contained'
              color='primary'
              type="submit">
              {confirmButtonText}
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}