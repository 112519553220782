import React, { useEffect, useContext } from 'react';

import {
  Box,
  IconButton
} from '@mui/material';

import ThumbUpOffAltRoundedIcon from '@mui/icons-material/ThumbUpOffAltRounded';
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';

import { Image, LoadableText } from '@components/atoms';
import { UserDetails } from '@components/molecules';
import { LikeAndCommentCounts } from '@components/organisms';
import { UserContext, ServicesContext, AccountOnboardingContext } from '@context';
import { useTheme } from '@emotion/react';
import { EventsContext } from '@context/events';

export default function TopicContent(props) {
  const { topic, isLoading, openCommentDialog } = props;
  const { user } = useContext(UserContext);
  var { accountOnboarding, loadAccountOnboarding } = useContext(AccountOnboardingContext);
  const { api } = useContext(ServicesContext);
  const theme = useTheme();
  const [likeCount, setLikeCount] = React.useState(0);
  const [likedByCurrentUser, setLikedByCurrentUser] = React.useState(false);
  const { trackEvent } = useContext(EventsContext);

  useEffect(() => {
    if(topic){
      setLikeCount(topic.likeCount);
      setLikedByCurrentUser(topic.likedByCurrentUser);
    }
  }, [topic]);

  const likeTopic = async () => {
    if(!likedByCurrentUser){
      trackEvent('Community:Topic:Like', { topicId: topic.id });
      setLikeCount(likeCount + 1);
      setLikedByCurrentUser(true);
      await api.put({ url: `community/topics/${topic.id}/like`, user });

      if(!accountOnboarding?.hasContributedToCommunity){
        loadAccountOnboarding();
      }
    }
  };

  return (
    <Box>
      <UserDetails user={topic?.user} date={topic?.createdAt} isLoading={isLoading} />
      <Box sx={{ typography: 'h4', paddingTop: 1 }}>
        <LoadableText text={topic?.name} isLoading={isLoading}/>
      </Box>
      <Box sx={{ typography: 'body1', paddingBottom: 0.5 }}>
        <LoadableText text={topic?.description} isLoading={isLoading} minHeight={50}/>
      </Box>
      {topic?.imageUrl &&
        <Box sx={{ paddingBottom: 1 }}>
          <Image src={topic?.imageUrl}
            sx={{ 
              [theme.breakpoints.down('md')]: {
                maxWidth: '80%'
              },
              [theme.breakpoints.only('md')]: {
                maxWidth: '50%'
              },
              [theme.breakpoints.up('lg')]: {
                maxWidth: '33%'
              }   
            }} />
        </Box>
      }
      <LikeAndCommentCounts likeCount={likeCount}
        commentCount={topic?.commentCount}
        parentId={topic?.id}
        isLoading={isLoading} />
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
        <Box>
          <IconButton aria-label="delete"
            sx={{ color: likedByCurrentUser ? '#E57373' : 'text.disabled' }}
            onClick={likeTopic}
            disabled={isLoading}>
            <ThumbUpOffAltRoundedIcon />
          </IconButton>
        </Box>
        <Box>
          <IconButton aria-label="delete"
            sx={{ color: 'text.disabled' }}
            onClick={openCommentDialog}
            disabled={isLoading}>
            <ChatBubbleRoundedIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}