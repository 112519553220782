import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { 
  Box,
  Button
} from '@mui/material';
import Step1 from './step-1';
import Step2 from './step-2';
import { useTheme } from '@emotion/react';
import { LoadingButton } from '@mui/lab';

import { COOKIE_NAMES, getCookie } from '@helpers/cookies';
import { UserContext, ServicesContext } from '@context';
import moment from 'moment';
import { EventsContext } from '@context/events';

export default function Client(props) {
  const { doAfterSignUp, goBack } = props;
  const { user, refresh } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  const { api } = useContext(ServicesContext);
  const theme = useTheme();
  const NUMBER_OF_STEPS = 2;
  const MIN_AGE = 17;
  const [activeStep, setActiveStep] = useState(0);

  const isLastStep = activeStep == NUMBER_OF_STEPS - 1;

  const handleBack = () => {
    if(activeStep == 0){
      goBack();
    }else{
      setActiveStep(activeStep - 1);
    }
  };

  const validationSchema = yup.object({
    firstName: yup
      .string('First Name is required')
      .required('First Name is required'),
    lastName: yup
      .string('Last Name is required')
      .required('Last Name is required'),
    dateOfBirth: yup
      .string('Date Of Birth is required')
      .required('Date Of Birth must be a valid date')
      .nullable()
      .test(
        'Date Of Birth',
        `Users must be ${MIN_AGE} or older to use Brace.`,
        (date) => moment().diff(moment(date), 'years') >= MIN_AGE
      )
      
  });

  const formik = useFormik({
    validateOnMount: true,
    initialValues:{
      firstName: '',
      lastName: '',
      dateOfBirth: null,
      country: '',
      injuryType: '',
      activityType: '',
      bio: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      if(!isLastStep){
        trackEvent('Onboarding:AccountDetails:Submit');
        setActiveStep(activeStep + 1);
        return;
      }
      setSubmitting(true);
      var inviteToken = getCookie(COOKIE_NAMES.INVITE_TOKEN);
      await api.put({ url: 'accounts/activate', user, 
        data: { 
          firstName: values.firstName, 
          lastName: values.lastName, 
          dateOfBirth: values.dateOfBirth, 
          role:'CLIENT', 
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, 
          inviteToken 
        } });
      await api.put({ url: 'users', user, data: {
        country: values.country,
        firstName: values.firstName,
        lastName: values.lastName,
        bio: values.bio,
        injury: {
          activityType: values.activityType,
          type: values.injuryType
        }
      } });

      trackEvent('Onboarding:ProfileDetails:Submit');
      setSubmitting(false);
      refresh();
  
      if(doAfterSignUp){
        doAfterSignUp();
      }
    }
  });

  return(
    <Box sx={{ width: '100%', textAlign: 'center', fontWeight: 'medium', flexGrow: 1, display: 'flex', flexDirection: 'column', 
      '.MuiFormHelperText-root':{
        color: theme.palette.error.main
      }  
    }}>

      <form onSubmit={formik.handleSubmit} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ flexGrow: 1 }}>
          {activeStep == 0 && <Step1 formik={formik} />}
          {activeStep == 1 && <Step2 formik={formik} />}
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, paddingBottom: 12, alignItems: 'center' }}>
          <LoadingButton  
            color='braceGreenLight'
            sx={{ py: 1, px: 4, fontSize: 'h6.fontSize', fontWeight: 'bold', width: '66%', minWidth: '250px' }}
            data-testid= 'index-sign-up-complete-button'
            variant='contained'
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting || !formik.isValid}
            type='submit'>
          Continue
          </LoadingButton>
          <Button onClick={handleBack}
            sx={{ marginTop: 1, fontWeight: 'bold' }}
            color='braceGreenDark'>
            Back
          </Button>
        </Box>
      </form>
    </Box>
  );
}