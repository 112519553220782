import React, { useContext, useEffect, useState } from 'react';
import { 
  Box, 
  Typography,
  Stack,
  Button
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { UserContext, ServicesContext } from '@context';
import { TextField, Avatar } from '@components/atoms';
import { LoadingButton } from '@mui/lab';
import { ImageUploader } from '@components/molecules';
import { useTheme } from '@emotion/react';
import { COOKIE_NAMES, getCookie } from '@helpers/cookies';

export default function Practitioner(props) {
  var { doAfterSignUp, goBack } = props;
  const theme = useTheme();
  const { user, refresh } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const [ name, setName ] = useState('');
  const [ organisationName, setOrganisationName ] = useState('');
  const [organisationImage, setOrganisationImage] = useState('');
  const [invites, setInvites] = useState([]);
  const [isLoadingInvites, setIsLoadingInvites] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if(user?.id){
      loadInvites();
    }
  }, [user]);

  const loadInvites = () => {
    setIsLoadingInvites(true);
    api.post({ url: 'invites/search', data: { inviteeEmail: 'me' }, user })
      .then((response) => {
        setInvites(response.results);
        setIsLoadingInvites(false);
      });
  };

  const save = async () => {
    setIsSaving(true);
    var inviteToken = getCookie(COOKIE_NAMES.INVITE_TOKEN);
    await api.put({ url: 'accounts/activate', user, data: { name, role:'PRACTITIONER', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, inviteToken } });
  
    const formData = new FormData();
    formData.append('name', organisationName);
    if(organisationImage){
      formData.append('image', organisationImage);
    }
  
    await api.post({ url: 'organisations', data: formData, user });
    
    setIsSaving(false);
    refresh();
    if(doAfterSignUp){
      doAfterSignUp();
    }
  };

  const rejectInvite = () => {
    setIsSaving(true);
    api.put({ url: `invites/${invites[0].id}/reject`, data: { }, user })
      .then(() => {
        setIsSaving(false);
        loadInvites();
      });
  };

  const acceptInvite = () => {
    setIsSaving(true);
    api.put({ url: `invites/${invites[0].id}/accept`, data: { }, user })
      .then(() => {
        setIsSaving(false);
        refresh();
      });
  };

  const getImageUrl = () => {
    if(organisationImage){
      var urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(organisationImage);
    }
    return '';
  };

  var invite = invites?.[0];

  return(
    <Box sx={{ textAlign: 'center', px: 2 }}>
      <Box sx={{ 
        py: 1,
        fontSize: 'body1.fontSize', 
        textAlign: 'center'
      }}>
        <Typography>Brace helps you and your clients in every step of their recovery journey.</Typography>
       
        {invites.length > 0 &&
          <Stack sx={{ marginTop: 4 }} alignItems='center'>
            <Typography>You have been invited to join:</Typography>
            <Typography variant='h5'>{invite?.organisationName}</Typography>
            <Stack sx={{ width: '66%', minWidth: '250px', marginTop: 4 }} direction='row' spacing={1}>
              <LoadingButton onClick={rejectInvite}
                data-testid='onboarding-button-invite-reject'
                sx={{ width: '50%' }}
                loading={isSaving}
                color='error'
                variant='outlined'>
                  Reject
              </LoadingButton>
              <LoadingButton sx={{ width: '50%' }}
                loading={isSaving}
                data-testid='onboarding-button-invite-accept'
                color='primary'
                variant='contained'
                onClick={acceptInvite}>
                  Accept
              </LoadingButton>
            </Stack>
          </Stack>
        }
        {(!isLoadingInvites && invites.length == 0 ) &&
        <>
          <Typography>{'Let\'s get your organisation set up!'}</Typography>
          <Stack spacing={2} p={2} alignItems={'center'}>
            <TextField 
              inputProps={{ 'data-testid': 'index-sign-up-text-name' }}
              sx={{ width: '66%', minWidth: '250px' }}
              size='small'
              disabled={isLoadingInvites}
              required
              label='Your Name'
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField 
              inputProps={{ 'data-testid': 'index-sign-up-text-organisation-name' }}
              sx={{ width: '66%', minWidth: '250px' }}
              disabled={isLoadingInvites}
              size='small'
              required
              label='Organisation Name'
              value={organisationName}
              onChange={(e) => setOrganisationName(e.target.value)}
            />
            <Box>
              <Typography>
              Upload Organisation Logo
              </Typography>
              <ImageUploader isEnabled={true} saveImage={(i) => {setOrganisationImage(i);}} imageId={'NewOrganisation'}>
                {!organisationImage &&
                  <Box  sx={{ 
                    p: 1,
                    borderRadius: 1,
                    width: 148, 
                    height: 148, 
                    border:'1px dashed', 
                    borderColor: theme.palette.text.disabled,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    cursor: 'pointer'
                  }}>
                    <CloudUploadIcon  sx={{ fontSize: 50 }} color='action'/>
                    <Typography variant='caption' color='text.secondary'>
                      Click to upload
                    </Typography>
                  </Box>
                }
                {organisationImage &&
                  <Avatar 
                    clickable={true}
                    size={148}
                    src={getImageUrl()}
                    iconType='imageUpload'
                    iconSize={50}/>
                }
              </ImageUploader>
            </Box>
            <Box sx={{ display: 'flex', marginTop: 4, flexDirection: 'column', width: '100%', alignItems: 'center' }}>
              <LoadingButton sx={{ width: '66%', minWidth: '250px' }}
                data-testid= 'index-sign-up-complete-button'
                disabled={name == '' || organisationName == '' || isLoadingInvites}
                variant='contained'
                loading={isSaving}
                color='primary'
                onClick={save}>
            Continue
              </LoadingButton>
              {goBack &&
              <Button onClick={goBack}>
                Back
              </Button>
              }
            </Box>
          </Stack>
        </>
        }
      </Box>
    </Box>
  );
}