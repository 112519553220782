import React from 'react';

import { 
  Box,
  TextField

} from '@mui/material';

export default function Step2(props) {
  const { formik } = props;

  return (
    <Box sx={{ height: '100%', minHeight: '300px', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ 
        fontSize: 'body1.fontSize', 
        textAlign: 'center',
        fontWeight: 'normal'
      }}>
        Now let’s add some profile details.
      </Box>
      <Box sx={{ flexGrow: 1, p : 3, py: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <TextField 
          InputLabelProps={{ shrink: true }}
          fullWidth
          size='small'
          label='If injured, what injury do you have?'
          placeholder='Ruptured ACL'
          id='injuryType'
          onChange={formik.handleChange}
          value={formik.values.injuryType}
          error={formik.touched.injuryType && Boolean(formik.errors.injuryType)}
          helperText={formik.touched.injuryType && formik.errors.injuryType}
        />
        <TextField 
          InputLabelProps={{ shrink: true }}
          fullWidth
          size='small'
          label='What activity do you take part in?'
          placeholder='Running, Football, Hockey...'
          id='activityType'
          onChange={formik.handleChange}
          value={formik.values.activityType}
          error={formik.touched.activityType && Boolean(formik.errors.activityType)}
          helperText={formik.touched.activityType && formik.errors.activityType}
        />
        <TextField 
          InputLabelProps={{ shrink: true }}
          fullWidth
          size='small'
          label='Add to your Brace Bio'
          placeholder='I’m a 800m runner from Dublin...'
          id='bio'
          multiline
          rows={4}
          onChange={formik.handleChange}
          value={formik.values.bio}
          error={formik.touched.bio && Boolean(formik.errors.bio)}
          helperText={formik.touched.bio && formik.errors.bio}
        />
       
      </Box>
    </Box>
  );
}