import React, { useState } from 'react';

import {
  Stack,
  Box,
  Button,
  Skeleton
} from '@mui/material';

import { styled } from '@mui/material/styles';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

const CounterButton = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  minWidth: 0,
  '.MuiButton-startIcon': {
    marginRight: theme.spacing(0.5)
    
  },
  '.MuiButton-startIcon > :nth-of-type(1)': {
    fontSize: '1rem'
  }
}));

export default function LikeAndCommentButtons(props) {
  const [likeCount, setLikeCount] = useState(0);
  const [likedByCurrentUser, setLikedByCurrentUser] = React.useState(false);

  React.useEffect(() => {
    setLikeCount(props.data.likeCount);
    setLikedByCurrentUser(props.data.likedByCurrentUser);
  }, [props.data.likeCount, props.data.likedByCurrentUser]);

  const handleLike = () => {
    if(!likedByCurrentUser){
      setLikeCount(likeCount + 1);
      setLikedByCurrentUser(true);
      props.likeAction();
    }
  };

  return (
    <Box>
      {!props.isLoading &&
        <Stack direction="row-reverse" spacing={1} sx={{ paddingRight: 1, flexGrow: 1 }}>
          <Box display='flex' >
            <CounterButton sx={{ p: 0, color: likedByCurrentUser ? '#E57373' : 'text.disabled' }} startIcon={<ThumbUpIcon />} onClick={handleLike}>
              {likeCount}
            </CounterButton>
          </Box>
          <Box display='flex' >
            <CounterButton sx={{ p: 0, color: 'text.disabled' }} startIcon={<ChatBubbleIcon/>} onClick={props.commentAction}>
              {props.data.commentCount}
            </CounterButton>
          </Box>
        </Stack>
      }
      {props.isLoading &&
        <Stack direction="row-reverse" spacing={1} sx={{ flexGrow: 1 }}>
          <Skeleton width={80} height={35}/>
        </Stack>
      }
    </Box>
  );
}